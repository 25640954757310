<template>
  <b-custom-tabs :tabs="tabs" />
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          title: 'Faturalar',
          key: 'button2',
        },
        {
          title: 'Müşteriler',
          key: 'button3',
        },
        {
          title: 'Giderler',
          key: 'button4',
        },
        {
          title: 'Tedarikçiler',
          key: 'button5',
        },
        {
          title: 'Kasa',
          key: 'button7',
        },
        {
          title: 'Stok',
          key: 'button8',
        },
        {
          title: 'Raporlar',
          key: 'button9',
        },
      ],
    }
  },
}
</script>
